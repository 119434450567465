import React,{ useState } from 'react'
import "../singleCareer/single.scss"
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice4';
import { axiosInstance } from '../../config';
import Spinner from '../../components/spinner';

const SingleReview = () => {

  const [info, setInfo] = useState({});
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.value);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleUpdate = async (id, e) => {
    e.preventDefault();
    try{
        const updatedIntern = { ...info }
        const update = await axiosInstance.put(`/review-form/${id}`, updatedIntern)
        setInfo({});
        dispatch(active(update.data))
        setUpdated(true);
    }catch(err){
        console.log(err);
    }
  }

  return (
    <div>
      {
        !data && <Spinner/>
      }
    {data && 
    <div className='single'>
        <div className='top'>
          <p>Review Form Details</p>
          <Link to='/reviews'> 
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="userShow">
            <div className="userShowTop">
              <div className="userShowTopTitle">
                <span className="userShowFullname">{data.name}</span>
              </div>
            </div>
            <div className="userShowBottom">
              <div className="userShowInfo">
                <span>Status: </span>
                <span className="userShowInfoTitle">{data.isVerified}</span>
              </div>
              <div className="userShowInfo">
                <span>Email: </span>
                <span className="userShowInfoTitle">{data.email}</span>
              </div>
              <div className="userShowInfo">
                <span>Review Title: </span>
                <span className="userShowInfoTitle">{data.title}</span>
              </div>
              <div className="userShowInfo">
                <span>Description: </span>
                <span className="userShowInfoTitle">{data.desc}</span>
              </div>
              <div className="userShowInfo">
                <span>Profile picture: </span>
                {
                    data.profilePic && 
                    <span className="userShowInfoTitle"><a href={`${data.profilePic}`} target="_blank" rel="noopener noreferrer">View</a></span>
                }
              </div>
            </div>
          </div>
          <div className="userUpdate">
            <span className="userUpdateTitle">EDIT</span>
            {updated && (
                <p className='updated'>
                  <span>Details Updated Successfully!</span>
                  <span onClick={() => setUpdated(false)} style={{ cursor: 'pointer' }} >X</span>
                </p>
            )}
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Email</label>
                  <input
                    type="text"
                    id = "email"
                    onChange={handleChange}
                    placeholder={data.email}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Title:</label>
                  <input
                    type="text"
                    id = "title"
                    onChange={handleChange}
                    placeholder={data.title}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Desc:</label>
                  <input
                    type="text"
                    id = "desc"
                    onChange={handleChange}
                    placeholder={data.desc}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Profile pic:</label>
                  <input
                    type="text"
                    id = "profilePic"
                    onChange={handleChange}
                    placeholder={data.profilePic}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Status</label>
                  <select name="status" onChange={handleChange} id="isVerified">
                    <option value={data.isVerified}>Select</option>
                    <option value="Not-verified">Not-verified</option>
                    <option value="Verified">Verified</option>
                  </select>
                </div>
                <button className="userUpdateButton" onClick={(e) => handleUpdate(data._id, e)}>Update</button>
              </div>
            </form>
          </div>
        </div>
    </div>
    }
    </div>
  )
}

export default SingleReview